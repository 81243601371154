import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { ModalController } from "@ionic/angular";
import { UtilityService } from "./utility.service";

@Injectable({ providedIn: "root" })
export class UserGuardService implements CanActivate {
    constructor(private router: Router, private modalController: ModalController, public utils: UtilityService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const isLoggedIn = localStorage.getItem("uid");
        if (!isLoggedIn) {
            // this.router.navigate(["./"]);
            return false;
        }
        if (isLoggedIn && !this.utils.fullName) {
            this.router.navigate(["./sign-in"]);
            return false;
        }
        return true;
    }


}
