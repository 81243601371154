<ion-header>
  <app-my-header showCloseButton="true" (onCloseEvent)="dismiss()"></app-my-header>
</ion-header>

<div class="modal-bg" *ngIf="utils.spinner">
    <ion-spinner></ion-spinner>
</div>

<ion-content>
    <div class="content">
        <span *ngIf="!utils.uid && !isOTPRequested">
            <p *ngIf="signInScreenMessage" class="p2 fw-b" style="font-size: 32px;">
                {{ signInScreenMessage }}
            </p>
            <span *ngIf="!signInScreenMessage">
                <div class="title-wrapper">
                    <div class="title mt4">{{title}}</div>
                    <div class="fs2 mt2">{{invoiceMessage}}</div>
                </div>
                <div class="form">
                    <br />
                    <ion-list lines="none">
                        <ion-item lines="none">
                            <ion-grid style="grid-gap: 100px">
                                <ion-row>
                                    <ion-col size="2">
                                        <ion-input mode="md" readonly="readonly" type="any" placeholder="+91">
                                        </ion-input>
                                    </ion-col>
                                    <ion-col size="8">
                                        <ion-input mode="md" type="tel" placeholder="Enter Phone Number" maxlength="10"
                                            [(ngModel)]="phoneNumber">
                                        </ion-input>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </ion-item>
                    </ion-list>
                </div>
            </span>

            <div class="ml3 mr3">
                <ion-button size="large" class="btn" (click)="signIn()" [attr.disabled]="phoneNumber?.length < 10">
                    Continue</ion-button>
                <br />
                <p class="ion-text-center" *ngIf="!isOTPRequested">
                    We will send OTP for verification
                </p>
            </div>
        </span>

        <span *ngIf="!utils.uid && isOTPRequested">
            <div class="title-wrapper">
                <div class="title">Verify OTP</div>
            </div>
            <div class="form">
                <br />
                <ion-list lines="none">
                    <ion-item lines="none">
                        <ion-input mode="md" type="tel" maxlength=6 placeholder="Enter OTP" [(ngModel)]="verifCode">
                        </ion-input>
                    </ion-item>
                </ion-list>
            </div>
            <div class="ml3 mr3">
                <ion-button size="large" expand="full" class="btn" [attr.disabled]="verifCode?.length < 6 || !isOTPRequested"
                    (click)="confirmOtp()">
                    Confirm OTP
                </ion-button>
                <br />

                <p class="ion-text-center" *ngIf="isOTPRequested && remainingSecond > 0">
                    Enter OTP
                    <span *ngIf="remainingSecond > 0">&nbsp;in {{remainingSecond}} seconds</span>
                </p>
            </div>
        </span>

        <span *ngIf="utils.uid && !utils.fullName && !signInScreenMessage">
            <div class="title-wrapper">
                <div class="title">Enter Name</div>
            </div>
            <div class="form">
                <br/>
                <ion-list lines="none">
                    <ion-item lines="none">
                        <ion-input mode="md" type="name" [(ngModel)]="username" placeholder="Full Name">
                        </ion-input>
                    </ion-item>
                </ion-list>
            </div>
            <div class="ml3 mr3">
                <ion-button [attr.disabled]=" !username " size="large" expand="full" class="btn" (click)="onSubmit()">
                    Submit
                </ion-button>
                <br />
            </div>
        </span>


    </div>
</ion-content>