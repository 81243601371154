import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { PromotionItemComponent } from './promotion-item/promotion-item.component';
import { ItemComponent } from './item/item.component';
import { CartItemsComponent } from './cart-items/cart-items.component';
import { AddItemPage } from './add-item/add-item.page';
import { AddLocalityPage } from './add-locality/add-locality.page';
import { TabsComponent } from './tabs/tabs.component';
import { MyHeaderComponent } from './my-header/my-header.component';
import { StorePromotionComponent } from './store-promotion/store-promotion.component';
import { EmployeeCartComponent } from './employee-cart/employee-cart.component';
import { BillingComponent } from './billing/billing.component';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { BalanceComponent } from './balance/balance.component';
import { EmployeesComponent } from './employees/employees.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { SettlementsComponent } from './settlements/settlements.component';
import { MapComponent } from './map/map.component';
import { OrderTimelineComponent } from './order-timeline/order-timeline.component';
import { TimeAgo } from '../../pipes/time-ago.pipe';
import { LabelPipe } from '../../pipes/label.pipe';
import { TimeMilli } from 'src/pipes/time-milli.pipe';
import { Ms } from '../../pipes/time-ago.pipe';
import { ObjectLengthPipe } from 'src/pipes/object-length.pipe';
import { StoreFooterComponent } from './store-footer/store-footer.component';
import { FiltersComponent } from './filters/filters.component';
import { PopOverComponent } from './popover/popover';
import { PictureComponent } from './picture/picture.component';
import { OrderItemComponent } from './order-item/order-item.component';
import { SelectDeliveryOptionComponent } from './select-delivery-option/select-delivery-option.component';
import { CompleteOrderComponent } from './complete-order/complete-order.component';
import { MyFooterComponent } from './my-footer/my-footer.component';
import { AddMapAddressComponent } from './add-map-address/add-map-address.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { CustomersComponent } from './customers/customers.component';

import { AddMoreItems } from './addmore-items/addmore-items.component';
import { CouponCustomersComponent } from './coupon-customers/coupon-customers.component';
import { QRCodeModule } from 'angularx-qrcode';
import { VisitingCardComponent } from './visiting-card/visiting-card.component';
import { MyCartComponent } from './my-cart/my-cart.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { ThankYouComponent } from './thankyou/thankyou.component';
import { FeedBackComponent } from './feedback/feedback.component';
import { StoreReviews } from './store-reviews/storereviews.component';
import { ShowStorePromotionsComponent } from './show-store-promotions/show-store-promotions.component';
import { FranchiseStoresComponent } from './franchise-stores/franchise-stores.component';
import { RequestStoreComponent } from './request-store/request-store.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ImageCropperModule,
        QRCodeModule
    ],
    declarations: [
        AddItemPage,
        AddLocalityPage,
        PromotionItemComponent,
        ItemComponent,
        CartItemsComponent,
        TabsComponent,
        MyHeaderComponent,
        MyFooterComponent,
        StorePromotionComponent,
        EmployeeCartComponent,
        BillingComponent,
        PaymentOptionsComponent,
        SettlementsComponent,
        TransactionsComponent,
        EmployeesComponent,
        BalanceComponent,
        MapComponent,
        OrderTimelineComponent,
        PopOverComponent,
        StoreFooterComponent,
        FiltersComponent,
        PictureComponent,
        OrderItemComponent,
        SelectDeliveryOptionComponent,
        AddMapAddressComponent,
        CompleteOrderComponent,
        CustomersComponent,
        CouponCustomersComponent,
        TimeAgo,
        ObjectLengthPipe,
        LabelPipe,
        TimeMilli,
        Ms,
        AddMoreItems,
        VisitingCardComponent,
        MyCartComponent,
        MyAccountComponent,
        ThankYouComponent,
        FeedBackComponent,
        StoreReviews,
        ShowStorePromotionsComponent,
        FranchiseStoresComponent,
        RequestStoreComponent

    ],
    exports: [
        PromotionItemComponent,
        TabsComponent,
        ItemComponent,
        CartItemsComponent,
        MyHeaderComponent,
        MyFooterComponent, // check if we need the components to be specified in both exports and declarations
        StorePromotionComponent,
        EmployeeCartComponent,
        BillingComponent,
        PaymentOptionsComponent,
        SettlementsComponent,
        TransactionsComponent,
        EmployeesComponent,
        BalanceComponent,
        OrderTimelineComponent,
        MapComponent,
        PopOverComponent,
        StoreFooterComponent,
        FiltersComponent,
        PictureComponent,
        OrderItemComponent,
        SelectDeliveryOptionComponent,
        AddMapAddressComponent,
        CompleteOrderComponent,
        CustomersComponent,
        CouponCustomersComponent,
        TimeAgo,
        ObjectLengthPipe,
        LabelPipe,
        TimeMilli,
        Ms,
        AddMoreItems,
        VisitingCardComponent,
        MyCartComponent,
        MyAccountComponent,
        ThankYouComponent,
        FeedBackComponent,
        StoreReviews,
        ShowStorePromotionsComponent,
        FranchiseStoresComponent,
        RequestStoreComponent

    ]
})

export class ComponentsModule { }
