import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonNav, IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { environment } from "src/environments/environment";
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";

import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx'
import { ImageCropperModule } from 'ngx-image-cropper';
import { soundService } from 'src/services/sound.service';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
import { IonicStorageModule } from '@ionic/storage-angular';

import { ComponentsModule } from "./components/components.module";
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicModule,
    AppRoutingModule,
    AngularFirestoreModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    IonicStorageModule.forRoot(),
    ComponentsModule,
    CommonModule,
    HttpClientModule,
    QRCodeModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: !isDevMode(),
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })

  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AndroidPermissions,
    NativeAudio,
    soundService,
    Geolocation,
    BluetoothSerial,
    ImageCropperModule,
    IonNav

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
