export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAnYOCJwIRlzx7-8W3mMmeD7-leNbkYNBQ",
    authDomain: "my-town-360.firebaseapp.com",
    projectId: "my-town-360",
    storageBucket: "my-town-360.appspot.com",
    messagingSenderId: "682269248335",
    appId: "1:682269248335:web:9e0ad38a1dc9580824933a",
    measurementId: "G-9S0M3XVSNZ"
  }
};
