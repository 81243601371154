import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { map } from "rxjs/operators";
import { UtilityService } from "./utility.service";

@Injectable({
    providedIn: "root",
})
export class LocationService {
    locationRef: AngularFirestoreCollection;

    constructor(public Afs: AngularFirestore, public utils: UtilityService) { }

    getLocations() {
        this.utils.spinner = true;
        this.locationRef = this.Afs.collection("locations");
        return this.locationRef.snapshotChanges().pipe(
            map((res) => {
                if (res && res.length === 0) {
                    this.utils.spinner = false;
                    return res;
                }
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    this.utils.spinner = false;
                    return { id, ...data };
                })
            })
        );
    }

    getAllLocations() {
        this.utils.spinner = true;
        this.locationRef = this.Afs.collection("locations");
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    this.utils.spinner = false;
                    return { id, ...data };
                })
            )
        );
    }

    searchLocation(searchQuery: string) {
        this.utils.spinner = true;
        this.locationRef = this.Afs.collection("locations", (ref) =>
            ref.where("keywords", "array-contains", searchQuery)
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) => {
                if (res && res.length === 0) {
                    this.utils.spinner = false;
                    return res;
                } else {
                    return undefined
                }
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;

                    this.utils.spinner = false;
                    return { id, ...data };
                })
            })
        );
    }

    getLocality() {
        this.locationRef = this.Afs.collection("locations", (ref) =>
            ref.where("entity", "==", "locality")
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getLocalitybyCreatedby(uid) {
        this.locationRef = this.Afs.collection("locations", (ref) =>
            ref.where("createdbyid", "==", uid)
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getMandals(district: string) {
        this.locationRef = this.Afs.collection("locations", (ref) =>
            ref.where("l", "==", district).where("entity", "==", "mandal")
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getStates() {
        this.locationRef = this.Afs.collection("locations", (ref) =>
            ref.where("entity", "==", "state")
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getDistricts(state) {
        this.locationRef = this.Afs.collection("locations", (ref) =>
            ref.where("entity", "==", "district").where("state", "==", state)
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getVillages(mandal: string) {
        this.locationRef = this.Afs.collection("locations", (ref) =>
            ref.where("mandal", "==", mandal).where("entity", "==", "village")
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    public async createCity(result) {
        result.adminname = this.utils.fullName || '';
        result.adminphone = this.utils.phoneNumber || '';
        result.adminid = this.utils.uid || '';
        this.locationRef = this.Afs.collection("locations");
        await this.locationRef.add({ ...result }).then((snapshot) => { });
    }

    public async updateCity(result) {
        this.locationRef = this.Afs.collection("locations");
        await this.locationRef.doc(result.id).set({ ...result });
    }

    public async deleteDupliateCities(locationId) {
        this.utils.spinner = true;
        this.locationRef = this.Afs.collection("locations");
        await this.locationRef.doc(locationId).delete();
        this.utils.spinner = false;
    }

    getDraftlocations(uid) {
        this.locationRef = this.Afs.collection("draftlocations", (ref) =>
            ref
                .where("createdbyid", "==", uid)
                .where("status", "==", "Location Approved")
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getDraftleaders(uid) {
        this.locationRef = this.Afs.collection("draftleaders", (ref) =>
            ref
                .where("createdbyid", "==", uid)
                .where("status", "==", "Leader Approved")
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getLocationDetails(locationName) {
        this.locationRef = this.Afs.collection("locations", (ref) =>
            ref.where("name", "==", locationName)
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getLocalities(locationName) {
        this.locationRef = this.Afs.collection("availablelocalities", (ref) =>
            ref.where("city", "==", locationName)
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }
}

export class City {
    name: string;
    mandal: string;
    state: string;
    country: string;
    district: string;
    entity: string;

    constructor() {
        this.name = "";
        this.mandal = "";
        this.state = "";
        this.country = "";
        this.district = "";
        this.entity = "village";
    }
}

export class Locality {
    name: string;
    city: string;
    entity: string;
    zone: string;
    nearbylocalities: string;
    zipcode: string;
    position: {};
    id: any;

    constructor() {
        this.name = "";
        this.city = "";
        this.zone = "";
        this.nearbylocalities = "";
        this.zipcode = "";
        this.entity = "locality";
        this.position = {}
    }

    reset() {
        this.name = "";
        this.city = "";
        this.zone = "";
        this.nearbylocalities = "";
        this.zipcode = "";
        this.entity = "locality";
        this.position = {};
    }
}
