import { Injectable } from "@angular/core";
// import { FirebaseAuthentication } from "@ionic-native/firebase-authentication/ngx";
import { Router } from "@angular/router";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { map } from "rxjs/operators";
import { UtilityService } from "./utility.service";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    usersRef: AngularFirestoreCollection;
    adminsRef: AngularFirestoreCollection;
    count = 0;

    constructor(
        // private fireAuth: FirebaseAuthentication,
        private router: Router,
        public Afs: AngularFirestore,
        public utils: UtilityService
    ) { }

    get windowRef() {
        return window;
    }

    getUserDetails(uid) {
        return this.Afs.collection("users").doc(uid).ref.get();
    }

    // sendOtpForIOS(phoneNumber) {
    //     return this.fireAuth.verifyPhoneNumber(phoneNumber, 60);
    // }

    checkAdmin(userId) {
        if (userId) {
            this.utils.spinner = true;
            this.adminsRef = this.Afs.collection("admins");
            return this.adminsRef
                .doc(userId)
                .snapshotChanges()
                .pipe(
                    map((res: any) => {
                        const result = res.payload.data();
                        if (result) {
                            result.id = res.payload.id;
                        }
                        this.utils.spinner = false;
                        return result;
                    })
                );
        }
    }

   async createUser(fullname, uid) {

        this.usersRef = this.Afs.collection("users");
      return await  this.usersRef
            .doc(uid)
            .set({
                fullname: fullname,
                createdon: this.utils.getNewDate(), // change the format and keep it same
                phonenumber: this.utils.phoneNumber
            })
            // .then((snapshot) => {
            //     this.utils.updateFullNameToLocalStorage(uid, fullname);
            //     this.router.navigate(["./"]);
            //     this.utils.spinner = false;
            // });
    }

    public async updateUserFullName(uid, name) {
        this.utils.spinner = true;
        this.usersRef = this.Afs.collection("users");
        await this.usersRef.doc(uid).update({ fullname: name });
        this.utils.spinner = false;
    }

    public async updateUserEmail(uid, email) {
        this.utils.spinner = true;
        this.usersRef = this.Afs.collection("users");
        await this.usersRef.doc(uid).update({ email: email });
        this.utils.spinner = false;
    }

    public async updateUserImage(uid, image) {
        this.utils.spinner = true;
        this.usersRef = this.Afs.collection("users");
        await this.usersRef.doc(uid).update({ pic: image });
        this.utils.spinner = false;
    }

    public async updateUserStoreId(uid) {
        this.utils.spinner = true;
        this.usersRef = this.Afs.collection("users");
        await this.usersRef.doc(uid).update({ storeid: uid });
        this.utils.spinner = false;
    }

    public async updateUserEmployeeId(uid) {
        this.utils.spinner = true;
        this.usersRef = this.Afs.collection("users");
        await this.usersRef.doc(uid).update({ employeeid: uid });
        this.utils.spinner = false;
    }

    public async updateStoreImage(uid, image) {
        this.utils.spinner = true;
        this.usersRef = this.Afs.collection("stores");
        await this.usersRef.doc(uid).update({ pic: image });
        this.utils.spinner = false;
    }

    async createAdmin(user) {
        const result: any = {
            fullname: user.fullname,
            createdon: this.utils.getNewDate(), // change the format and keep it same
            phonenumber: user.phonenumber,
            uid: user.id
        }
        if (user.pic) {
            result.pic = user.pic;
        }
        this.usersRef = this.Afs.collection("admins");
      return await  this.usersRef
            .doc(user.id)
            .set(result)
            // .then((snapshot) => {
            //     this.utils.updateFullNameToLocalStorage(uid, fullname);
            //     this.router.navigate(["./"]);
            //     this.utils.spinner = false;
            // });
    }

    public async updateAdminDetails(uid, result) {
        this.utils.spinner = true;
        this.usersRef = this.Afs.collection("admins");
        await this.usersRef.doc(uid).update(result);
        this.utils.spinner = false;
    }

    // public async updateStoreIdInUserDetails(uid, storeId) {
    //   this.utils.spinner = true;
    //   this.usersRef = this.Afs.collection("users");
    //   await this.usersRef.doc(uid).update({ storeid: storeId });
    //   this.utils.updateStoreIdToLocalStorage(uid, storeId);
    //   this.utils.spinner = false;
    // }


    updateLoggedInInfoToLocalStorage(uid, phoneNumber) {
        localStorage.setItem("uid", uid);
        localStorage.setItem(uid + "phonenumber", phoneNumber);
    }

    updateUserLatLongToLocalStorage(uid,latlong) {
        localStorage.setItem(uid + "userlatlong", JSON.stringify(latlong));
    }


    public getUserByPhoneNumber(phoneNumber) {
        this.utils.spinner = true;
        this.usersRef = this.Afs.collection('users', (ref) =>
            ref.where('phonenumber', '==', phoneNumber)
        );
        return this.usersRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                if (!res || res.length === 0) {
                    return [];
                }
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            })
        );
    }

}
