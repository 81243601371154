export class Location {
    sublocality_level_1: string;
    sublocality_level_2: string;
    locality: string;
    administrative_area_level_2: string;
    state: string;
    country: string;
    postal_code: string;
    administrative_area_level_1: string;
    latlong: any;
    formatted_address: string;

    //     {
    //     sublocality: "sublocality_level_1", sidarthnagar, uppal
    //         locality: "locality", hanamkonda, hyderabad
    //             state: "administrative_area_level_1", telangana
    //                 district: "administrative_area_level_2", warangal
    //                     country: country, india
    //                         zipcode: zip 506013
    // }

    // {
    //     "sublocality_level_1": 'Uppal',
    //     "sublocality_level_2": 'Kalaynpuri',
    //     "locality": 'Hyderabad';

    //     "administrative_area_level_2": 'RR District',
    //     "state": 'Telangana'
    //     "country": 'India'
    //     "zipcode": "506013"
    //     "lat:''
    //     "long": ''
    // }

    // getLocationMap() {
    //     return {
    //         sublocality_level_1: 'sublocality',
    //         sublocality_level_2: 'sublocality_level_2',
    //         locality: 'locality',
    //         administrative_area_level_2: 'district',
    //         administrative_area_level_1: 'state',
    //         country: 'country',
    //         postal_code: 'zipcode',
    //         latlong: 'latlong'
    //     }
    // }

    constructor(location?) {
        this.sublocality_level_1 = (location && location.sublocality_level_1) || "";
        this.sublocality_level_2 = (location && location.sublocality_level_2) || "";
        this.locality = (location && location.locality) || "";
        this.administrative_area_level_2 = (location && location.administrative_area_level_2) || "";
        this.administrative_area_level_1 = (location && location.administrative_area_level_1) || "";
        this.country = (location && location.country) || "";
        this.postal_code = (location && location.postal_code) || "";
        this.formatted_address = (location && location.formatted_address) || "";
    }
}