import { Injectable } from '@angular/core';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { Platform } from '@ionic/angular';
@Injectable()
export class soundService {
    audioType: string = 'html5';
    sounds: any = [];
    constructor(public nativeAudio: NativeAudio, platform: Platform) {
        if (platform.is('cordova')) {
            this.audioType = 'native';
        }
    }
    preload(key, asset) {
        if (this.audioType === 'html5') {
            let audio = {
                key: key,
                asset: asset,
                type: 'html5'
            };
            console.log(audio, 'html5');

            this.sounds.push(audio);
        } else {
            this.nativeAudio.preloadSimple(key, asset);
            let audio = {
                key: key,
                asset: key,
                type: 'native'
            };
            this.sounds.push(audio);
        }
    }
    play(key) {
        // var a = new Audio("https://firebasestorage.googleapis.com/v0/b/my-town-360.appspot.com/o/notification-sound-2.mp3?alt=media&token=74149222-245e-4734-b413-fb787582a045");
        
        // var resp = a.play();

        // if (resp !== undefined) {
        //     resp.then(_ => {
        //         // autoplay starts!
        //     }).catch(error => {
        //         //show error
        //     });
        // }
        
        this.preload('notificationSound', 'assets/audio/notification-sound.mp3');
        let audio = this.sounds.find((sound) => {
            return sound.key === key;
        });
        if (audio.type === 'html5') {
            let audioAsset = new Audio(audio.asset);
            audioAsset.play();
        } else {


            this.nativeAudio.play(audio.asset).then((res) => {
                console.log(res);
            }, (err) => {
                console.log(err);
            });
        }
    }
}