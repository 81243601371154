import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { map } from "rxjs/operators";
import { UtilityService } from "./utility.service";
import { Store } from "../models/store.model";
import { Observable } from "rxjs";
// import * as firebase from 'firebase/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Locality } from "./location.service";
import { StoreValidate } from "../validations/store.validate";

@Injectable({
    providedIn: "root",
})
export class StoreService {
    stores: Observable<any>;
    storeRef: AngularFirestoreCollection;
    testDoc;
    docId = 'testPoint' + Date.now();

    constructor(
        public Afs: AngularFirestore,
        public utils: UtilityService,
        private storeValidate: StoreValidate) { }

    ///// gets ------------------------------------------------------------------
    ///////----------------------------------------------------------------------

    public getStoresByCreated(uid): Observable<any> {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("stores", (ref) =>
            ref.where("createdbyid", "==", uid)
        );
        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    public getStoresByStoreRequest(): Observable<any> {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("stores", (ref) =>
            ref.where("status", "==", 'storerequest')
        );
        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    public getAdminsPayRequests(): Observable<any> {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("storestats", (ref) =>
            ref.where("adminpayrequest", "==", true)
        );
        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    // getStorebyGeoHash( ) {
    //   const start = `9qg5ux7r`
    //   const end = start + '~'

    //   this.storeRef = this.Afs.collection("stores", (ref) =>
    //   ref.orderBy('position')
    //   .startAt(start)
    //   .endAt(end)
    // );
    // return this.storeRef.snapshotChanges().pipe(
    //   map((res) => {
    //     this.utils.spinner = false;
    //     return res.map((dataItems) => {
    //       const data = dataItems.payload.doc.data(),
    //         id = dataItems.payload.doc.id;
    //       return { id, ...data };
    //     });
    //   })
    // );

    // }

    getStorebyGeoHash(latlong): Observable<any> {
        const lat = latlong.latitude
        const long = latlong.longitude



        const center = new firebase.firestore.GeoPoint(lat, long);
        const radius = 10; // km
        const field = 'position';
        // this.stores = this.geofirex.query('stores').within(center, radius, field, { log: true });

        return this.stores.pipe(
            map((arr: any) => {
                // arr.find(o => o.id === this.docId)
                return arr.map((dataItem) => {
                    // const data = dataItems.payload.doc.data(),
                    //   id = dataItems.payload.doc.id;

                    return { ...dataItem };
                });
            })
        );
    }

    // not used now but can be in the future
    // public getStoresInlocality(locality): Observable<any> {
    //   this.utils.spinner = true;
    //   this.storeRef = this.Afs.collection("stores", (ref) =>
    //     ref.where("locationname", "==", locality)
    //   );
    //   return this.storeRef.snapshotChanges().pipe(
    //     map((res) => {
    //       this.utils.spinner = false;
    //       return res.map((dataItems) => {
    //         const data = dataItems.payload.doc.data(),
    //           id = dataItems.payload.doc.id;
    //         return { id, ...data };
    //       });
    //     })
    //   );
    // }

    public getStoresLocality(location): Observable<any> {
        this.utils.spinner = true;
        if (location === this.utils.location.sublocality) {
            this.storeRef = this.Afs.collection("stores", (ref) =>
                ref.where("address.sublocality", "==", location).limit(30)
            );
        } else if (location === this.utils.location.locality) {
            this.storeRef = this.Afs.collection("stores", (ref) =>
                ref.where("address.locality", "==", location).limit(30)
            );
        }

        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    public getPromotedStores(): Observable<any> {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("stores", (ref) =>
        ref.where("adminpromoted", "==", true).limit(30)
        );

        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }


    public getStoreExclusivePromotions(location, userPhoneNumber): Observable<any> {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("stores", (ref) =>
            ref.where("exclusivepromotioncustomers", "array-contains", userPhoneNumber)
                .where("exclusivepromotion.deal", ">", 0).limit(30)
        );

        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    public getStoresByBusinessTypeSublocality(limit, type): Observable<any> {
        this.utils.spinner = true;
        const storeReference: AngularFirestoreCollection = this.Afs.collection("stores", (ref) =>
            ref
                .where("address.sublocality", "==", this.utils.location.sublocality)
                .where("type", "==", type)
                .orderBy("updatedon", "desc")
                .limit(limit)
        );

        return storeReference.snapshotChanges().pipe(
            map((res) => {

                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    public getStoresByBusinessTypeLocality(limit, type): Observable<any> {
        this.utils.spinner = true;
        const storeReference: AngularFirestoreCollection = this.Afs.collection("stores", (ref) =>
            ref
                .where("address.locality", "==", this.utils.location.locality)
                .where("type", "==", type)
                .orderBy("updatedon", "desc")
                .limit(limit)
        );

        return storeReference.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;

                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    // This Query can be used when using the infinite scroll
    public getStoresByTypeCategory(
        type,
        category,
        lastDoc,
        limit
    ): Observable<any> {
        let newLastDoc = null;
        if (lastDoc) {
            this.storeRef = this.Afs.collection("stores", (ref) =>
                ref
                    .where("address.sublocatity", "==", this.utils.location.sublocality)
                    .where("type", "==", type)
                    .where("categories", "array-contains", category)
                    .orderBy("updatedon", "desc")
                    .startAfter(lastDoc)
                    .limit(limit)
            );
        } else {
            this.utils.spinner = true;
            this.storeRef = this.Afs.collection("stores", (ref) =>
                ref
                    .where("zone", "==", this.utils.location.zone)
                    .where("type", "==", type)
                    .where("categories", "array-contains", category)
                    .orderBy("updatedon", "desc")
                    .limit(limit)
            );
        }

        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                if (res.length > 0) {
                    newLastDoc = res[res.length - 1].payload.doc;
                } else {
                    newLastDoc = null;
                }
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data, lastDoc: newLastDoc };
                });
            })
        );

    }

    public getStoresByStoreName(name, limit): Observable<any> {
        let newLastDoc = null;
        this.storeRef = this.Afs.collection("stores", (ref) =>
            ref
                .where("address.locality", "==", this.utils.location.locality)
                .where("keywords", "array-contains", name)
                .orderBy("updatedon", "desc")
                .limit(limit)
        );

        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                if (res.length > 0) {
                    newLastDoc = res[res.length - 1].payload.doc;
                } else {
                    newLastDoc = null;
                }
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data, lastDoc: newLastDoc };
                });
            })
        );
    }

    public getStoreItems(storeId): Observable<any> {
        this.utils.spinner = true;
        if (storeId) {
            this.storeRef = this.Afs.collection("stores");
            return this.storeRef
                .doc(storeId)
                .collection("items")
                .snapshotChanges()
                .pipe(
                    map((res) => {
                        this.utils.spinner = false;
                        return res.map((dataItems) => {
                            const data = dataItems.payload.doc.data(),
                                id = dataItems.payload.doc.id;
                            return { id, ...data };
                        });
                    })
                );
        }
    }

    public getStoreEmployees(storeId): Observable<any> {
        this.utils.spinner = true;
        if (storeId) {
            this.storeRef = this.Afs.collection("stores");
            return this.storeRef
                .doc(storeId)
                .collection("employees")
                .snapshotChanges()
                .pipe(
                    map((res) => {
                        this.utils.spinner = false;
                        return res.map((dataItems) => {
                            const data = dataItems.payload.doc.data(),
                                id = dataItems.payload.doc.id;
                            return { id, ...data };
                        });
                    })
                );
        } else {
            return null;
        }
    }

    public getStoreInfo(storeId) {
        this.utils.spinner = true;
        if (storeId) {
            this.storeRef = this.Afs.collection("stores");
            return this.storeRef
                .doc(storeId)
                .snapshotChanges()
                .pipe(
                    map((res: any) => {
                        this.utils.spinner = false;
                        const result = res.payload.data();
                        if (result) {
                            result.id = res.payload.id;
                        }
                        return result;
                    })
                );
        } else {
            return null;
        }
    }

    public getStoreInfoByName(storeId) {
        this.utils.spinner = true;

            this.storeRef = this.Afs.collection("stores", (ref) =>
                ref.where("domainname", "==", storeId)
            );
            return this.storeRef.snapshotChanges().pipe(
                map((res: any) => {
                    this.utils.spinner = false;
                    return res.map((dataItems) => {
                        const data = dataItems.payload.doc.data(),
                            id = dataItems.payload.doc.id;
                        return { id, ...data };
                    });
                })
            );
    }

    public getFranchiseStores(name): Observable<any> {
        this.utils.spinner = true;
            this.storeRef = this.Afs.collection("stores", (ref) =>
            ref.where("keywords", "array-contains", name)
                .where("isfranchise", "==", true)
                .where("isparent", "==", '1')
            );

        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    public getChildStores(name): Observable<any> {
        this.utils.spinner = true;
            this.storeRef = this.Afs.collection("stores", (ref) =>
            ref.where("childof", "==", name));

        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    // result.billing = JSON.parse(JSON.stringify(result.billing));
    //     result.useraddress = JSON.parse(JSON.stringify(result.useraddress));
    //     delete result.store;
    //     let isValidated: any = await this.orderValidate.isValid(result);
    //     if (isValidated?.text === 'success') {
    //         this.orderRef = this.Afs.collection<Order>('orders');
    //         return await this.orderRef.doc(orderId).set({ ...result }, { merge: true });
    //     }
    //     return isValidated;

    public async claimCreateStore(uid, result) {
        this.utils.spinner = true;
        let isValidated: any = await this.storeValidate.isValid(result);
        if (isValidated?.text === 'success') {
            this.storeRef = this.Afs.collection<Store>("stores");
            await this.storeRef.doc(uid).set({ ...result });
            this.utils.spinner = false;
            return isValidated;
        }
        this.utils.spinner = false;
        return isValidated;
    }

    public async createStoreByAdmin(uid, result) {
        this.utils.spinner = true;
        delete result.promotion;
        delete result.itempromotion;
        result = this.utils.updateCreatedDetailsInModel(result);
        result.keywords = this.utils.getKeyWordsForStore(result.name);
        result.open = "mopen";
        const storeId = this.utils.getRandomNumber();

        let isValidated: any = await this.storeValidate.isValid(result);
        if (isValidated?.text === 'success') {
            this.storeRef = this.Afs.collection<Store>("stores");
            this.storeRef.doc(storeId).set({ ...result });
            this.utils.spinner = false;
            return isValidated;
        }

        this.utils.spinner = false;
        return isValidated;
    }

    public async updateEntireStore(id, result) {
        this.utils.spinner = true;
        result = this.utils.updateUpdatedDetailsInModel(result);

        let isValidated: any = await this.storeValidate.isValid(result);
        if (isValidated?.text === 'success') {
            this.storeRef = this.Afs.collection("stores");
            await this.storeRef.doc(id).set({ ...result });
            if (this.utils.cart) {
                delete this.utils.cart;
            }
            this.utils.spinner = false;
            return isValidated;
        }

        this.utils.spinner = false;
        return isValidated;
    }

    public async updateStore(storeId, result) {
        this.utils.spinner = true;
        if (result.name) {
            result.keywords = this.utils.getKeyWordsForStore(result.name);
        }
        this.storeRef = this.Afs.collection("stores");
        await this.storeRef.doc(storeId).update(result);
        if (this.utils.cart) {
            delete this.utils.cart;
        }
        this.utils.spinner = false;
    }

    public async addEmployeetoSubCollection(storeId, empId, result) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection<Store>("stores");
        await this.storeRef
            .doc(storeId)
            .collection("employees")
            .doc(empId)
            .set({ ...result }, { merge: true });
        this.utils.spinner = false;
    }

    public async updateEmployeetoSubCollection(storeId, empId, result) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection<Store>("stores");
        await this.storeRef
            .doc(storeId)
            .collection("employees")
            .doc(empId)
            .update(result);
        this.utils.spinner = false;
    }

    public async deleteEmployee(storeId, itemId) {
        this.utils.spinner = true;
        itemId = itemId || this.utils.getRandomNumber();
        this.storeRef = this.Afs.collection<Store>("stores");
        await this.storeRef.doc(storeId).collection("employees").doc(itemId).delete();
        this.utils.spinner = false;
    }


    // Create, Update and Delete uses the same function
    public async createUpdateItems(storeId, result) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection<Store>("stores");
        await this.storeRef
            .doc(storeId)
            .collection("items")
            .doc(result.id)
            .set({ ...result }, { merge: true });
        this.utils.spinner = false;
    }

    // we have to start using this for item updates
    public async updateStoreItem(storeId, result) {
        this.utils.spinner = true;
        const itemsRef = this.Afs.collection("stores").doc(storeId).collection("items");
        await itemsRef.doc(result.id).update(result);
        this.utils.spinner = false;
    }

    public async updateItemImage(storeId, itemId, image) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("stores");
        await this.storeRef
            .doc(storeId)
            .collection("items")
            .doc(itemId)
            .update({ pic: image });
        this.utils.spinner = true;
    }


    public async deleteItem(storeId, itemId) {
        this.utils.spinner = true;
        itemId = itemId || this.utils.getRandomNumber();
        this.storeRef = this.Afs.collection<Store>("stores");
        await this.storeRef.doc(storeId).collection("items").doc(itemId).delete();
        this.utils.spinner = false;
    }

    public async deleteStore(storeid) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("stores");
        await this.storeRef.doc(storeid).delete();
        let recentVisits = JSON.parse(
            localStorage.getItem(this.utils.uid + "recentVisits")
        );
        let index = recentVisits.findIndex((store) => store.id === storeid);
        if (index > -1) {
            recentVisits.splice(index, 1);
        }
        localStorage.setItem(
            this.utils.uid + "recentVisits",
            JSON.stringify(recentVisits)
        );
        this.utils.spinner = false;
    }

    public async createCoupon(storeId, result) {
        result = JSON.parse(JSON.stringify(result));
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("coupons");
        await this.storeRef.doc(storeId).set(result);//{ ...result }
        this.utils.spinner = false;
    }

    public async updateCoupon(storeId, limit) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("coupons");

        await this.storeRef.doc(storeId).update({
            claimed: firebase.firestore.FieldValue.arrayUnion(this.utils.phoneNumber),
            limit: limit
        });
        this.utils.spinner = false;
    }

    public getActiveCoupon(storeId): Observable<any> {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("coupons", (ref) =>
            ref.where("storeid", "==", storeId)
        );

        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    public checkCoupon(storeId, code): Observable<any> {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("coupons", (ref) =>
            ref.where("storeid", "==", storeId)
                .where("code", "==", code)
                .where('claimed', "not-in", [[this.utils.phoneNumber]])
        );

        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    public async deleteCoupon(storeid) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("coupons");
        await this.storeRef.doc(storeid).delete();
        this.utils.spinner = false;
    }

    public async createUpdateStats(storeId, result) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection<Store>("storestats");
        await this.storeRef
            .doc(storeId)
            .set({ ...result }, { merge: true });
        this.utils.spinner = false;
    }

    public getStoreStats(storeId) {
        this.utils.spinner = true;
        if (storeId) {
            this.storeRef = this.Afs.collection("storestats");
            return this.storeRef
                .doc(storeId)
                .snapshotChanges()
                .pipe(
                    map((res: any) => {
                        this.utils.spinner = false;
                        const result = res.payload.data();
                        if (result) {
                            result.id = res.payload.id;
                        }
                        return result;
                    })
                );
        } else {
            return undefined
        }
    }

    public async adminRequestPayment(storeId, result) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("storestats");

        await this.storeRef.doc(storeId).set(
            { admin: result, adminpayrequest: true }, { merge: true }
        );
        this.utils.spinner = false;
    }

    public async createUpdateStoreSubscription(storeId, result) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("storeSubscriptions");
        await this.storeRef
            .doc(storeId)
            .set({ ...result }, { merge: true });
        this.utils.spinner = false;
    }

    public async subscribeStore(storeId, phoneNumber) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("stores");

        await this.storeRef.doc(storeId).update({
            subscribers: firebase.firestore.FieldValue.arrayUnion(phoneNumber),
        });
        this.utils.spinner = false;
    }

    public async unsubscribeStore(storeId, uid) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("storeSubscriptions");

        await this.storeRef.doc(storeId).update({
            subscribers: firebase.firestore.FieldValue.arrayRemove(uid),
        });
        this.utils.spinner = false;
    }

    public getStoreSubscribers(storeId) {
        this.utils.spinner = true;
        if (storeId) {
            this.storeRef = this.Afs.collection("stores");
            return this.storeRef
                .doc(storeId)
                .snapshotChanges()
                .pipe(
                    map((res: any) => {
                        this.utils.spinner = false;
                        const result = res.payload.data();
                        if (result) {
                            result.id = res.payload.id;
                        }
                        return result;
                    })
                );
        } else {
            return null;
        }
    }

    public getSubscribedStores(phoneNumber): Observable<any> {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("stores", (ref) =>
            ref.where("subscribers", "array-contains", phoneNumber)
        );

        return this.storeRef.snapshotChanges().pipe(
            map((res) => {
                this.utils.spinner = false;
                return res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    public async submitReview(storeId, review) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("stores");

        await this.storeRef.doc(storeId).update({
            reviews: firebase.firestore.FieldValue.arrayUnion(review),
        });
        this.utils.spinner = false;
    }

    public async removeReview(storeId, review) {
        this.utils.spinner = true;
        this.storeRef = this.Afs.collection("stores");

        await this.storeRef.doc(storeId).update({
            reviews: firebase.firestore.FieldValue.arrayRemove(review),
        });
        this.utils.spinner = false;
    }

}
