import { Component, NgZone, OnInit } from "@angular/core";
import { Platform, LoadingController, AlertController, ModalController } from "@ionic/angular";
import { UtilityService } from "../services/utility.service";
import { Observable } from "rxjs/internal/Observable";
import { StorePage } from "./store/store.page";
import { OrderService } from "src/services/order.service";
import { soundService } from "src/services/sound.service";
import { Storage } from '@ionic/storage-angular';
import { Order } from "src/models/order.model";
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PushNotifications } from '@capacitor/push-notifications';

import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { UpdateService } from "src/services/update.service";
import { Browser } from "@capacitor/browser";
import { StoreService } from "src/services/store.service";
import { LocationService } from "src/services/location.service";
import firebase from 'firebase/compat/app';
import { environment } from "src/environments/environment";
import { PushNotificationService } from "src/services/push-notification.service";
import { SwUpdate } from "@angular/service-worker";
import { SignInPage } from "./sign-in/sign-in.page";
import { FlowWrapperModel } from "./flowwrappermodel/flowwrappermodel.page";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
    store: any;
    addStore = false;
    UploadedImageURL: Observable<string>;
    selectTab: any;
    scannedData: {};
    storeId: string;
    storeLocality: any;


    constructor(
        private platform: Platform,
        public utils: UtilityService,
        public loadingCtrl: LoadingController,
        private orderService: OrderService,
        private soundService: soundService,
        private router: Router,
        private storage: Storage,
        private location: Location,
        private zone: NgZone,
        private updateService: UpdateService,
        private storeService: StoreService,
        private locationService: LocationService,
        private pushNotificationService: PushNotificationService,
        private swUpdate: SwUpdate,
        private alertController: AlertController,
        private modalController: ModalController
    ) {
        this.initializeApp();
        this.utils.projectSettings = this.utils.getProjectSettings();
    }

    async initializeApp() {
        const app = firebase.initializeApp(environment.firebase);
        const platforms = this.platform.platforms();
        const appalert = JSON.parse(localStorage.getItem('appalert')) || false;

        if (platforms.includes('mobileweb') || platforms.includes('desktop')) {
            this.utils.platform = 'browser';
            localStorage.setItem('platform', 'browser');
        } else if (platforms.includes('android') || platforms.includes('capacitor')) {
            this.deepLink();
            this.utils.platform = 'android';
            localStorage.setItem('platform', 'android');
            this.pushNotificationEnable();
        } else if (platforms.includes('ios')) {
            this.utils.platform = 'ios';
            localStorage.setItem('platform', 'ios');
        }

        if (this.swUpdate.versionUpdates) {
            this.swUpdate.versionUpdates.subscribe(() => {
                if (confirm('A new version is available. Load it?')) {
                    window.location.reload();
                }
            });
        }

        if (Capacitor.isNativePlatform()) {
            this.updateService.checkAppUpdate();
            this.utils.platform = 'android';
            localStorage.setItem('platform', 'android');
        }

        if (!appalert && platforms.includes('mobileweb') && platforms.includes('android') && platforms.includes('mobile')) {
            this.utils.showOpeninApp();
            console.log('open app');
        }

        await this.soundService.preload('notificationSound', 'assets/audio/notification-sound.mp3');
    }

    async ngOnInit() {
        window.addEventListener('online', this.handleOnlineStatusChange.bind(this));
        window.addEventListener('offline', this.handleOnlineStatusChange.bind(this));

        this.storage.create(); // are we checking of already checking before creating it or creating new everytime
        this.checkLocation();
        // this.initializeApp();
        this.utils.initialize(); // this will get location, uid, userinfo from local storage

        // this.redirectPage();
        this.getAllOrders();
        this.checkForOrders();
        this.pushNotificationService.requestPermission();
        this.pushNotificationService.receiveMessage();

    }

    checkForOrders() {
        //Mytown360.com/oi/justchicken/7095959589/3134593040(orderid)
        
        const url = this.location.path();
        if (url.includes('/oi/')) {
            const urlParts = url.split('/oi/')[1].split('/');
            const storeId = urlParts[0];
            const phoneNumber = urlParts[1];
            const orderId = urlParts[2];
            const newInvoice = {
                storeid: storeId,
                phonenumber:phoneNumber,
                orderid: orderId
            }
            this.saveOrderInLocalStorage(newInvoice);

            setTimeout((v)=> {
                if(storeId) {
                    this.router.navigate(["./" + storeId]);
                }
            },500)
          
            // if (storeId && orderId) {
            //     this.router.navigate(["./" + storeId]);
            //     const orderSubscription = this.orderService.getOrderDetails(orderId).subscribe((res)=> {
            //         orderSubscription.unsubscribe();
            //         this.saveOrderInLocalStorage(res);
            //         this.showNewOrderAlert();
            //     });
            // }
        }
       
    }

    saveOrderInLocalStorage(res) {
        localStorage.setItem('newinvoice', JSON.stringify(res));
        this.utils.newinvoice = res;
    }

    handleOnlineStatusChange() {
        // Update the isOnline property when online/offline status changes
        this.utils.isOnline = navigator.onLine;
    }

    getAllOrders() {
        if (this.utils.uid) {
            this.loadUserOrders(this.utils.uid);
        }
        if (this.utils.newinvoice && this.utils.phoneNumber) {
            this.loadUserByNumber(this.utils.phoneNumber);
        }
        if (this.utils.userOwnStore && this.utils.userOwnStore.id === this.utils.uid) {
            this.loadStoreOwnerOrders(this.utils.userOwnStore.id);
        }
    }

    loadStoreOwnerOrders(storeId) {
        const lastCheckedDate = this.utils.getStoreOrdersLastCheckedDate();
        const StoreOrderSubscription = this.orderService.getStoreOrders(storeId, lastCheckedDate).subscribe((res: Array<Order>) => {
            const rLen = res?.length || 0;
            if (rLen > 0 && res[rLen - 1].updatedon.toDate() > lastCheckedDate) {
                this.utils.processStoreOrders(res);
                StoreOrderSubscription.unsubscribe();
                this.loadStoreOwnerOrders(this.utils.userOwnStore.id);
            }
        });
    }

    checkLocation() {
        const location = localStorage.getItem("location");
        if (!location) {
            const path = this.location.path();

            // const newinviteduser = path.split("/sign-in/")[1];
            // if (newinviteduser && newinviteduser?.charAt(0) === 'g') {
            //     // localStorage.setItem('newinviteduser', newinviteduser.slice(2));

            // }
            const storeId = path.split("/")[1];

            if (storeId && !this.utils.location) {
                const storeInfoSubscription = this.storeService.getStoreInfoByName(storeId).subscribe((result) => {
                    if (result !== undefined) {
                        const storeInfo = result[0];
                        this.storeLocality = storeInfo.address.locality
                    }
                    const locationInfoSubscription =
                        this.locationService.getAllLocations().subscribe((res: any) => {
                            locationInfoSubscription.unsubscribe();
                            const locations = res;
                            const selectLocation =
                                locations.filter(v => v.locality === this.storeLocality);
                            this.utils.location = selectLocation[0];
                            localStorage.setItem("location", JSON.stringify(selectLocation[0]));
                            this.router.navigate(["/" + storeId]);
                        });
                    storeInfoSubscription.unsubscribe();
                    return;
                });
            } else {
                const defaultLocation = { "id": "Ms3NknXxMd7gfzdPmv5d", "adminphone": "3333333333", "latlong": { "latitude": 17.9689008, "longitude": 79.59405439999999 }, "sublocality2": "", "zipcode": "506002", "state": "Telangana", "district": "Warangal", "streetaddress": "Warangal, Telangana, India", "adminid": "aCWrBlVkulZnlogstooar646M4I2", "adminname": "Rahul", "locality": "Warangal", "sublocality": "", "country": "India", "keywords": ["warangal", "wara", "waran", "warang", "waranga"] };
                localStorage.setItem("location", JSON.stringify(defaultLocation));
                this.utils.reloadPage();
            }
        }
    }

    loadUserOrders(uid) {
        const lastCheckedDate = this.utils.getUserOrdersLastCheckedDate();
        this.orderService.getUserOrders(uid, lastCheckedDate).subscribe((res) => {
            const rLen = res?.length || 0;
            if (rLen > 0 && res[rLen - 1].updatedon.toDate() > lastCheckedDate) {
                this.utils.processUserOrders(res);
            }
        });
    }

    loadUserByNumber(phonenumber) {
        this.utils.setUserOrdersLastCheckedDateforNewUser();
        let lastCheckedDate = this.utils.getUserOrdersLastCheckedDate();
        this.orderService.getUserOrdersNewUser(phonenumber, lastCheckedDate).subscribe((res) => {
            const rLen = res?.length || 0;
            if (rLen > 0 && res[rLen - 1].updatedon.toDate() > lastCheckedDate) {
                this.utils.processUserOrders(res);
                localStorage.removeItem('newinvoice');
                this.utils.newinvoice = null;
            }
        });
    }



    pushNotificationEnable() {
        PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
            } else {
                // Show some error
            }
        });
    }

    deepLink() {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                const domain = 'mytown360.com/';
                console.info('deep', event);
                console.log('deep', event);
                const pathArray = event.url.split(domain);
                console.info('deeplink', event.url);
                console.log('deeplink', event.url);

                const storeId = pathArray.pop();
                if (storeId) {
                    this.router.navigate(["/" + storeId]);
                }
            })
        })
    }

    // dynamicLink() {
    //     // for send data to app through url (used for refferal)  
    //     this.firebaseDynamicLinks.onDynamicLink().subscribe((res: any) =>  {
    //         console.log('dynamiclink',res);
    //     })
    // }

}
