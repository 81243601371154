import { Injectable } from '@angular/core';
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import 'firebase/messaging';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  pushNotificationToken:  string = null;
  constructor( private messaging: AngularFireMessaging) { }

  requestPermission() {
    this.messaging.requestToken.subscribe((token) => {
      this.pushNotificationToken = token;
    });
  }

  receiveMessage() {
    this.messaging.messages.subscribe((payload) => {
      console.log(payload);
      
    }

    );
  }

}