import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { UtilityService } from "../../services/utility.service";
import { AngularFirestore } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

import { OrderService } from "src/services/order.service";
import { StoreService } from "src/services/store.service";
import { PushNotifications } from '@capacitor/push-notifications';
import { AngularFireMessaging } from "@angular/fire/compat/messaging";

@Component({
    selector: "app-sign-in",
    templateUrl: "./sign-in.page.html",
    styleUrls: ["./sign-in.page.scss"],
})
export class SignInPage implements OnInit, OnDestroy {
    windowRef: any;
    interval: any;
    phoneNumber = "";
    verifCode = "";
    username = "";
    verificationId: any;
    isOTPRequested = false;
    remainingSecond = 60;
    newinviteduser: string;
    attempts: number = 0;

    signInScreenMessage = '';
    from: any;
    title: string = "Sign In";
    invoiceMessage: string;

    constructor(
        private authService: AuthService,
        public utils: UtilityService,
        private orderService: OrderService,
        private router: Router,
        private afs: AngularFirestore,
        private modalController: ModalController,
        private messaging: AngularFireMessaging
    ) { }

    ionViewWillEnter() {
        //  this.resetAll();
        this.windowRef = this.authService.windowRef;
    }

    ngOnInit(): void {
        const path = this.router.url;
        this.newinviteduser = path.split("/sign-in/")[1];
        if (this.newinviteduser && this.newinviteduser.charAt(0) === 'g') {
            // localStorage.setItem('newinviteduser', this.newinviteduser.slice(2));
            localStorage.setItem('newinviteduser', 'true');
            this.utils.showToast('Invite');
        }
        if (this.from === 'invoice') {
            this.title = "Verify Phone Number";
            // need to change text verify email
            this.invoiceMessage = 'You have new invoice please verify phone number to view.';
        }
        // check if we have something in the url that starts with g-
        // if we do then say newinviteduser = phonnumber; (localstorage);
        this.resetAll();
        this.checkLoginAttempts();
    }

    checkLoginAttempts() {
        const optRequested = JSON.parse(localStorage.getItem('otprequested'));
        if (optRequested) {
            const timeDiff = this.utils.getNewDate().getTime() - optRequested.split('-')[0];
            this.attempts = parseInt(optRequested.split('-')[1]);
            if (this.attempts > 5) { // more than 5 attempts in less than half hour
                if (timeDiff < 300000) {
                    this.signInScreenMessage = 'Too many attempts. Login suspended for an hour. Please try later!';
                    return;
                }

                this.signInScreenMessage = '';
                localStorage.setItem('otprequested', JSON.stringify(this.utils.getNewDate().getTime() + '-3'));
                return;
            }
            if (timeDiff < 60000) {
                if (this.attempts < 6) {
                    this.signInScreenMessage = 'OTP Request sent to the provided phone. Please wait for a minute before entering again!';
                    return;
                }
            }
        }
    }

    resetAll() {
        this.phoneNumber = "";
        this.verifCode = "";
        this.isOTPRequested = false;
        // this.enableResend = false;
        this.remainingSecond = 60;
    }

    dismiss() {
        this.resetAll();
        this.modalController.dismiss();
    }

    signIn() {
        if (this.interval !== undefined) {
            clearInterval(this.interval);
        }
        if (!this.phoneNumber) {
            this.utils.showToast("Phone number is required");
            return false;
        }
        if (!this.validatePhoneNumber()) {
            this.utils.showToast("Phone number is not valid");
            return false;
        }


        if (this.utils.platform === "ios") {
            // this.handleIOSLogin();
        } else {
            this.handleWebAndroidLogin();
        }
    }

    validatePhoneNumber() {
        let pattern = /[1-9]{1}[0-9]{9}/;
        if (pattern.test(this.phoneNumber)) {
            return true;
        }
        return false;
    }


    handleWebAndroidLogin() {
        this.sendOtpForWebAndroid().then((res) => {
            this.remainingSecond = 60;
            this.attempts = this.attempts + 1;
            localStorage.setItem('otprequested', JSON.stringify(this.utils.getNewDate().getTime() + '-' + this.attempts));
        },
            (err) => {
                alert(err);
            }
        );
        this.setRemainingSeconds();
    }

    setRemainingSeconds() {
        this.interval = setInterval(() => {
            this.remainingSecond = this.remainingSecond - 1;
            if (this.remainingSecond === 0) {
                this.isOTPRequested = false;
                this.signInScreenMessage = 'OTP Expired, Please try again after a minute.';
                clearInterval(this.interval);
            }
        }, 1000);
    }

    sendOtpForWebAndroid() {
        this.utils.spinner = true;
        if (!this.windowRef.recaptchaVerifier) {
            this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                "recaptcha-container",
                { size: "invisible" }
            );
        }
        const num =
            this.utils.projectSettings.indiaPhonenNumberPreFix +
            this.phoneNumber.toString();
        return firebase
            .auth()
            .signInWithPhoneNumber(num, this.windowRef.recaptchaVerifier)
            .then((result) => {
                this.utils.spinner = false;
                this.isOTPRequested = true;
                this.utils.showToast("OTP Sent Successfully " + this.phoneNumber);
                this.windowRef.confirmationResult = result;
            })
            .catch((err) => {
                this.utils.spinner = false;
                this.utils.showToast(err);
            });
    }

    confirmOtp() {
        if (!this.verifCode || this.verifCode.length === 0) {
            this.utils.showToast("OTP is required");
            return false;
        } else if (this.verifCode.length !== 6) {
            this.utils.showToast("OTP is not valid");
            return false;
        } else {
            this.utils.spinner = true;
            if (this.utils.platform === "ios") {
                this.confirmOtpForIOS();
            } else {
                this.confirmOtpForWebAndroid();
            }
        }
    }


    confirmOtpForIOS() {
        const signInCredential = firebase.auth.PhoneAuthProvider.credential(
            this.verificationId,
            this.verifCode
        );
        firebase
            .auth()
            .signInWithCredential(signInCredential)
            .then((result) => {
                this.processConfirmOTPSuccess(result);
            }, err => {
                if (err) {
                    this.processOTPError();
                }
                this.utils.spinner = false;
            });
    }

    confirmOtpForWebAndroid() {
        return this.windowRef.confirmationResult
            .confirm(this.verifCode)
            .then((result) => {
                this.processConfirmOTPSuccess(result);
            }, err => {
                if (err.code === "auth/invalid-verification-code") {
                    this.processOTPError();
                }
                this.utils.spinner = false;
            });
    }

    processConfirmOTPSuccess(result) {
        if (result && result.user && result.user.uid) {
            this.utils.uid = result.user.uid;
            localStorage.removeItem('otprequested');
            this.signInScreenMessage = 'Setting up user account.';
            this.isOTPRequested = false;
            this.setUserLoggedInToLocalStorage(result.user.uid);
            this.getUserDetails(result.user.uid);
        } else {
            this.processOTPError();
        }
    }

    processOTPError() {
        localStorage.setItem('otprequested', JSON.stringify(this.utils.getNewDate().getTime() + 60000 + '-' + this.attempts));
        this.utils.showToast("Incorrect OTP");
        this.signInScreenMessage = 'Incorrect OTP. Please try again after some time.';
        this.isOTPRequested = false;
        this.utils.spinner = false;
    }

    getUserDetails(uid) {
        this.authService.getUserDetails(uid).then(
            (res) => {
                const userInfo = res.data();
                this.utils.spinner = false;
                this.loadUserOrders();
                if (!userInfo || userInfo === undefined) {
                    // if (this.newinviteduser) {
                    //     this.loadUserOrders();
                    // }
                } else {
                    this.utils.setUserInfoInLocalStorage(userInfo, uid);
                    this.saveTokenToFirestore(uid);
                    if (this.utils.cart) {
                        // this.router.navigate(['/' + this.utils.cart.storeid + '/cart']);
                    } else {
                        this.router.navigate(["./"]);
                    }
                    setTimeout(v => {
                        this.utils.showToast("LoggedIn Successfully");
                        this.modalController.dismiss();
                        // this.utils.reloadPage();
                    }, 1000)
                }
                this.signInScreenMessage = '';
            },
            (err) => {
                alert(err);
                // this.router.navigate(["./sign-in"]);
                this.modalController.dismiss();
                this.utils.spinner = false;
            }
        );
    }

    async setUserLoggedInToLocalStorage(uid) {
        this.authService.updateLoggedInInfoToLocalStorage(uid, this.phoneNumber);
        this.utils.uid = uid;
        this.utils.phoneNumber = this.phoneNumber;
    }

    async saveTokenToFirestore(uid) {
        if (this.utils.platform === "android") {

            let permStatus = await PushNotifications.checkPermissions();

            if (permStatus.receive === 'prompt') {
                permStatus = await PushNotifications.requestPermissions();
            }

            if (permStatus.receive !== 'granted') {
                throw new Error('User denied permissions!');
            }

            await PushNotifications.register();

            PushNotifications.addListener('registration', async token => {
                console.info('Registration token: ', token.value);
                localStorage.setItem('token', token.value);
                if (
                    localStorage.getItem("uid") !== null &&
                    localStorage.getItem("uid") !== undefined
                ) {
                    if (!token.value) return;
                    console.log(token.value);
                    const devicesRef = this.afs.collection("devices");
                    const docData = {
                        token: token.value,
                        userid: uid,
                    };
                    await devicesRef.doc(uid).set(docData); //set, update
                }
            });


        }
        if (this.utils.platform === 'browser') {
            this.messaging.requestToken.subscribe(async (token) => {
                console.info('Registration token: ', token);
                localStorage.setItem('token', token);
                if (
                    localStorage.getItem("uid") !== null &&
                    localStorage.getItem("uid") !== undefined
                ) {
                    if (!token) return;
                    console.log(token);
                    const devicesRef = this.afs.collection("devices");
                    const docData = {
                        token: token,
                        userid: uid,
                    };
                    await devicesRef.doc(uid).set(docData); //set, update
                }
            });
        }
    }


    async onSubmit() {
        if (this.username) {
            await this.loadUserOrders();
            this.utils.spinner = true;
            this.authService.createUser(this.username, this.utils.uid).then((res) => {
                console.log(res);
                this.utils.spinner = false;
                this.utils.updateFullNameToLocalStorage(this.utils.uid, this.username);
                this.saveTokenToFirestore(this.utils.uid);
                this.router.navigate(["./"]);
                this.modalController.dismiss();
            })
        } else {
            this.utils.spinner = false;
            this.utils.showToast("Full name should be entered");
        }
        return true;
    }

    async loadUserOrders() {
        this.utils.setUserOrdersLastCheckedDateforNewUser();
        let lastCheckedDate = this.utils.getUserOrdersLastCheckedDate();
        const newinviteduser = localStorage.getItem('newinviteduser');

        // Making sure its the same phone number the user used to login in
        //if (this.utils.phoneNumber && newinviteduser === 'true') {
        if (this.utils.phoneNumber) {

            await this.orderService.getUserOrdersNewUser(this.utils.phoneNumber, lastCheckedDate).subscribe((res) => {
                const rLen = res?.length || 0;
                if (rLen > 0 && res[rLen - 1].updatedon.toDate() > lastCheckedDate) {
                    this.utils.processNewInvitedUserOrder(res);
                    localStorage.removeItem(this.utils.uid + "newinviteduser");
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.resetAll();
    }

}