import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { map } from "rxjs/operators";
import { UtilityService } from "./utility.service";
import { Observable } from "rxjs";
import { App } from '@capacitor/app';
import { AlertController } from "@ionic/angular";
import { NativeMarket } from "@capgo/native-market";
import { AppUpdate, AppUpdateInfo, AppUpdateResultCode } from '@capawesome/capacitor-app-update';


@Injectable({
    providedIn: "root",
})
export class UpdateService {

    configRef: AngularFirestoreCollection;
    config: any;
    localVersion: any;
    appUpdateInfo: any;

    constructor(
        public Afs: AngularFirestore,
        public utils: UtilityService,
        private alertController: AlertController,
        // private appVersion: AppVersion
    ) { }

    checkUpdate() {
        App.getInfo().then((res: any) => {
            console.log(res);
            this.localVersion = res.version;
        });

        this.getConfigData().subscribe((res) => {
            const config: any = res[0];
            console.log(res);
            const localAppVersion = 1 //test/ prod :  this.localVersion
            if (config.appversion > this.localVersion) {
                this.showUpdateAlert();
            }

        });
    }


    getConfigData() {
        this.utils.spinner = true;
        this.configRef = this.Afs.collection("config");
        return this.configRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    this.utils.spinner = false;
                    return { id, ...data };
                })
            )
        );
    }

    async showUpdateAlert() {
        const alert = await this.alertController.create({
            header: 'New Update Available',
            message: "My Town 360 recommends that you update to the latest version.",
            buttons: [
                // { text: "Cancel" },
                {
                    text: "Update",
                    handler: (data: any) => this.openPlayStore(),
                }
            ],
            backdropDismiss: false
        });
        await alert.present();
    }

    openPlayStore() {
        NativeMarket.openStoreListing({
            appId: "com.mytown360.app",
        });
    }

    checkAppUpdate() {
        AppUpdate.getAppUpdateInfo().then(async appUpdateInfo => {
            this.appUpdateInfo = appUpdateInfo;
            console.log('appinfo', appUpdateInfo);

            if (appUpdateInfo.availableVersion > appUpdateInfo.currentVersion) {
                await AppUpdate.startFlexibleUpdate().then(AppUpdateResult => {
                    console.log(AppUpdateResult);
                });
            }
          });
    }

}