import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { ModalController, NavController } from "@ionic/angular";
import { SignInPage } from "src/app/sign-in/sign-in.page";
import { UtilityService } from "./utility.service";

@Injectable({ providedIn: "root" })
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private modalController: ModalController,
    public utils: UtilityService,
    private navCtrl: NavController
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isLoggedIn = localStorage.getItem("uid");
    const fullName = localStorage.getItem(this.utils.uid + 'fullname');
    if (!isLoggedIn && !fullName || fullName === null) {
      this.openSignIn()
      return false;
    }
    return true;
  }

  async openSignIn() {
    const modal = await this.modalController.create({
      component: SignInPage,
    });
    modal.onDidDismiss().then((res) => {
      if (this.utils.uid && this.utils.fullName) {
        if (this.utils.cart) {
          delete this.utils.cart.useraddress,
            this.utils.cart.deliveryoption,
            this.utils.cart.deliverydistance,
            this.utils.cart.tablenumber
          localStorage.setItem("cart", JSON.stringify(this.utils.cart));
          this.navCtrl.back();
        }
        this.utils.reloadPage();
      }
    });
    return await modal.present();
  }

}
